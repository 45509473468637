import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Layout, StyledHeroImage, Content } from '../../../components';

const WholesaleThanks = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    return (
        <Layout title="Thank You" description="Thank you for your enquiry">
            <StyledHeroImage vh={60} image={frontmatter.image} className="hero" />
            <Content>
                <h1
                    style={{ margin: '30px 0' }}
                    className="has-text-centered is-size-4-mobile is-size-2-tablet is-size-2-widescreen"
                >
                    Thanks for your wholesale enquiry and we'll get back to you soon.
                </h1>
            </Content>
        </Layout>
    );
};

WholesaleThanks.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                image: PropTypes.string.isRequired,
            }),
        }),
    }),
};
export const pageQuery = graphql`
    query WholesaleContactPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "wholesale-page" } }) {
            frontmatter {
                image
            }
        }
    }
`;

export default WholesaleThanks;
